@import '../../mixins';

.header {
  position: fixed;
  width: 100%;
  top: 0;
  will-change: background, box-shadow;
  transition: background, box-shadow 0.3s, 0.3s ease-in-out;
  transform: translate3d(0, 0, 0);
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0);
  z-index: 1000;
  
  @include breakpoint(phone-portrait) {
    background: transparent;
    transition: none;
  }

  @at-root {
    html:not([data-scroll='0']) {
      .header {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
        &__container {
          padding: 10px 0;
        }
        @include breakpoint(phone-portrait) {
          background: transparent;
        }
      }
    }
  }

  &__container {
    @include contentWidth;
    margin: 0 auto;
    display: flex;
    padding: 30px 0;
    will-change: padding;
    transition: padding 0.3s ease-in-out;
    transform: translate3d(0, 0, 0);
    align-items: center;

    @include breakpoint(phone-portrait) {
      width: 100% !important;
    }
  }

  &__logoHolder {
    flex-grow: 1;

    @include breakpoint(phone-portrait) {
      position: fixed;
      top: 0;
      margin-left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }

    &__link {
      width: 160px;
      height: 40px;
      display: block;
      transform: translate3d(0, 0, 0);
      will-change: width, height;
      transition: width, height 0.3s, 0.3s ease-in-out;
          
      &__text {
        @include visuallyHidden;
      }

      &__image {
        will-change: filter;
        transition: filter 0.3s ease-in-out;
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0));
        .fillable, .pathable {
          transition: fill, stroke 0.3s, 0.3s ease-in-out;
          transform: translate3d(0, 0, 0);
        }
        .fillable {
          fill: #333333;
        }
        .pathable {
          stroke: #333333;
        }
        @include breakpoint(phone-portrait) {
          .fillable {
            fill: #fff;
          }
          .pathable {
            stroke: #fff;
          }
        }
      }

      @at-root {
        html[data-scroll='0'] {
          .header__logoHolder__link {
            width: 160px;
            height: 40px;
      
            &__image {
              filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.7));
              .fillable {
                fill: #fff;
              }
              .pathable {
                stroke: #fff;
              }
            }
          }
        }
      }
    }
  }

  &__nav {
    vertical-align: center;
    .mobileMenu {
      display: none;
    }
    @include breakpoint(phone-portrait) {
      .mobileMenu {
        display: block;
        z-index: 3;
        position: fixed;
        top: 4px;
        left: 4px;

        &__label {
          background: url(./menu.svg) no-repeat center;
          background-size: 16px 15px;
          width: 35px;
          height: 35px;
          display: block;
        }
        &__input {
          position: fixed;
          top: -20px;
        }
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 43px;
      position: fixed;
      top: 0;
      left: 0;
      background: $blue;
      z-index: 2;
      display: none;
      
      @include breakpoint(phone-portrait) {
        display: block;
      }
    }

    &__list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;

      @include breakpoint(phone-portrait) {
        display: block;
        position: fixed;
        right: 100%;
        width: 200px;
        top: 43px;
        height: calc(100vh - 43px);
        background: $blue-lighter;
        padding: 10px 0;
      }

      &::before {
        @include breakpoint(phone-portrait) {
          content: 'Menu';
          text-indent: 20px;
          line-height: 44px;
          color: $white;
          text-align: left;
          letter-spacing: 1px;
          position: absolute;
          top: -2.6em;
          font-weight: 800;
        }
      }

      &::after {
        @include breakpoint(phone-portrait) {
          content: '';
          background-size: 60px 30px;
          height: 30px;
          width: 60px;
          display: block;
          margin: 15px auto 0;
          background: url(./weights.svg) no-repeat;
        }
      }
      
      &__item {
        margin: 0 0 0 0.5em;
        padding: 0;

        @include breakpoint(phone-portrait) {
          padding: 10px 20px !important;
          font-weight: 300;
          letter-spacing: 0.9px;
          margin: 0;
        }

        &:first-of-type {
          display: none;
          @include breakpoint(phone-portrait) {
            display: block;
          }
        }

        &:last-of-type > a {
          @include breakpoint(phone-portrait) {
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          }
        }

        &__link {
          padding: 0.4em 0.8em 0.5em;
          text-decoration: none;
          color: #fff;
          filter: drop-shadow(0 0 1px rgba(0, 0, 0, 1));
          font-weight: 400;
          font-size: 1rem;
          will-change: filter, color;
          transition: filter, color 0.3s, 0.3s ease-in-out;
          transform: translate3d(0, 0, 0);
          
          @at-root {
            html:not([data-scroll='0']) {
              .header__nav__list__item__link {
                color: #333333;
                filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0));
                &--active {
                  color: #fff;
                  filter: none;
                }
              }
            }
          }

          @include breakpoint(phone-portrait) {
            color: $white !important;
            padding: 0;
            display: block;
          }

          &--active {
            color: #fff;
            background: $blue;
            filter: none;
            @include breakpoint(phone-portrait) {
              background: transparent;
              font-weight: 800;
            }
          }
        }
      }
    }
  }
}
