$blue: #2b2e4a;
$blue-lighter: #4a4e6b;
$red: #d64545;
$grey-lighter: #cacaca;
$grey: #9f9fa0;
$grey-darker: #999;
$grey-darkest: #444;
$white: #fff;
$off-white: #f7f7f7;

@mixin visuallyHidden() {
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}

.visually_hidden {
  @include visuallyHidden();
}

@mixin button() {
  margin: 1.5em 40px 0;
  background: $red;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  padding: 15px 50px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-shadow: none;
  font-family: 'Roboto', Roboto, Arial, sans-serif;
}

@mixin contentWidth($type: 'container') {
  /* HD TV, LANDSCAPE */
  @media (min-width: 1280px) {
    @if ($type == 'container') { width: 1000px; }
    @else { width: 700px; }
  }

  /* WIDESCREEN DESKTOP, LANDSCAPE */
  @media (max-width: 1280px) {
    @if ($type == 'container') { width: 1000px; }
    @else { width: 600px; }
  }

  /* TABLET, LANDSCAPE */
  @media (max-width: 1024px) {
    @if ($type == 'container') { width: 900px; }
    @else { width: 600px; }
  }

  /* WIDESCREEN DESKTOP, PORTRAIT */
  @media (max-width: 1000px) {
    @if ($type == 'container') { width: 800px; }
    @else { width: 500px; }
  }

  /* TABLET, PORTRAIT */
  @media (max-width: 850px) {
    @if ($type == 'container') { width: 700px; }
    @else { width: 500px; }
  }

  /* SMALL TABLET, PORTRAIT */
  @media (max-width: 750px) {
    @if ($type == 'container') { width: 90%; }
    @else { width: 100%; }
  }

  /* MEDIUM PHONE, LANDSCAPE */
  @media (max-width: 675px) {
    @if ($type == 'container') { width: 90%; }
    @else { width: 100%; }
  }

  /* PHONE, LANDSCAPE */
  @media (max-width: 480px) {
    @if ($type == 'container') { width: 90%; }
    @else { width: 100%; }
  }
  @media (max-width: 420px) {
    @if ($type == 'container') { width: 90%; }
    @else { width: 100%; }
  }

  /* PHONE, PORTRAIT */
  @media (max-width: 320px) {
    @if ($type == 'container') { width: 90%; }
    @else { width: 100%; }
  }
  
}

@mixin heroHeight($additional) {
  /* HD TV, LANDSCAPE */
  @media (min-width: 1280px) {
    height: 700px + $additional;
  }

  /* WIDESCREEN DESKTOP, LANDSCAPE */
  @media (max-width: 1280px) {
    height: 600px + $additional;
  }

  /* TABLET, LANDSCAPE */
  @media (max-width: 1024px) {
    height: 600px + $additional;
  }

  /* WIDESCREEN DESKTOP, PORTRAIT */
  @media (max-width: 1000px) {
    height: 500px + $additional;
  }

  /* TABLET, PORTRAIT */
  @media (max-width: 850px) {
    height: 400px + $additional;
  }

  /* SMALL TABLET, PORTRAIT */
  @media (max-width: 750px) {
    height: 400px + $additional;
  }

  /* MEDIUM PHONE, LANDSCAPE */
  @media (max-width: 675px) {
    height: 320px + $additional;
  }

  /* PHONE, LANDSCAPE */
  @media (max-width: 480px) {
    height: 250px + $additional;
  }

  @media (max-width: 420px) {
    height: 200px + $additional;
  }

  /* PHONE, PORTRAIT */
  @media (max-width: 320px) {
    height: 180px + $additional;
  }
  
}

@mixin breakpoint($size) {
  @if $size == phone-portrait {
    @media (max-width: 720px) { @content; }
  }
  @else if $size == phone-landscape {
    @media (max-width: 720px) { @content; }
  }
}

@mixin visuallyHidden {
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}
