@import '../../mixins';

.slideshow {
  @include heroHeight(100);
  overflow: hidden;
  position: relative;
  
  &__fitter {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    &--active {
      opacity: 1;
    }
  }
}
