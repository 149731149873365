@import '../../mixins';

.latestTweets {
  @include contentWidth;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @include breakpoint(phone-portrait) {
    flex-direction: column;
  }

  &__loading {
    padding: 0 0 3em;
    margin: 0;
    width: 100%;
    text-align: center;
    font-family: Open Sans, 'Open Sans', OpenSans, sans-serif;
    font-size: 1em;
    font-weight: 600;
    font-style: italic;
    color: $grey;
  }

  &__article {
    width: 48%;
    padding: 0 0 2em;
    
    @include breakpoint(phone-portrait) {
      width: 100%;
    }

    &__content {
      padding: 0 0 1em;

      > a {
        color: $red;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__meta {
      > a {
        color: $grey;
        text-decoration: none;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
