@import '../../mixins';

.home {
  @include contentWidth;
  margin: 0 auto;
  @include heroHeight(100);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @include breakpoint(phone-portrait) {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    height: auto !important;
  }

  &__cta {
    position: absolute;
    line-height: 1.4em;
    font-size: 1.2em;
    width: 380px;
    transform: translateY(50%) translateX(40px);
    color: #212121;
    bottom: 200px;
    text-shadow: -1px -1px 0 rgba(255,255,255,0.7), 
                  1px -1px 0 rgba(255,255,255,0.7), 
                 -1px  1px 0 rgba(255,255,255,0.7),
                  1px  1px 0 rgba(255,255,255,0.7);

    @include breakpoint(phone-portrait) {
      font-size: 1em;
      padding: 30px 0 0 !important;
      text-align: center;
      color: $grey-darkest;
      text-shadow: -1px -1px 0 rgba(255,255,255,0.7), 
                    1px -1px 0 rgba(255,255,255,0.7), 
                   -1px  1px 0 rgba(255,255,255,0.7),
                    1px  1px 0 rgba(255,255,255,0.7),
                   -2px -1px 0 rgba(255,255,255,0.5), 
                    2px -1px 0 rgba(255,255,255,0.5), 
                   -2px  1px 0 rgba(255,255,255,0.5),
                    2px  1px 0 rgba(255,255,255,0.5),
    }
    /* SMALL TABLET, PORTRAIT */
    @media (max-width: 750px) {
      position: relative;
      width: 100%;
      transform: none;
      bottom: auto;
      padding: 90px 0 30px;
    }


    &__pullout {
      font-size: 1.7em;
      text-align: center;
      line-height: 1.3em;
      margin: 0 0 0.6em;
      
      @include breakpoint(phone-portrait) {
        margin: -7em 0 2.5em;
      }

      &__button {
        display: block;
        font-size: 0.65em;
        @include button();
        padding: 10px 25px;
      }
    }

    &__content {
      font-size: 1.2em;
      text-shadow: -1px -1px 0 rgba(255,255,255,0.8), 
                    1px -1px 0 rgba(255,255,255,0.8), 
                   -1px  1px 0 rgba(255,255,255,0.8),
                    1px  1px 0 rgba(255,255,255,0.8);
    }
  }

  &__tweets {
    @include contentWidth;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    
    &__user {
      background: url('./logo-icon.svg') no-repeat center left;
      background-size: 50px;
      padding: 2em 0 2em 70px;

      &__name {
        font-size: 1.4em;
        line-height: 1.4em;
        margin: 0 0 2px;
      }

      &__link {
        font-size: 1em;
        line-height: 1em;
        margin: 0;
        
        > a {
          color: $red;
          text-decoration: none;
        
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__follow {
      margin: 2em 0;
    }
  }
}
