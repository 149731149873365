@import '../../mixins';

.about {
  &__trainers {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;

    @include breakpoint(phone-portrait) {
      flex-direction: column;
    }

    &__bio {
      width: 47.5%;

      @include breakpoint(phone-portrait) {
        width: 100%;
      }

      &__image {
        width: 100%;
      }
    }
  }

  &__repsLogo {
    margin-left: 50%;
    transform: translateX(-50%);
  }
  &__repsStatement {
    text-align: center;
  }
}
