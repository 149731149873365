@import './mixins';
@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,300,300italic,400italic,700italic);
@import url(https://fonts.googleapis.com/css?family=ABeeZee:400italic&text=“”);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic);

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', Roboto, Arial, sans-serif;
  line-height: 1.4em;
  color: #212121;
  width: 100%;
  overflow-x: hidden;
  background: $blue;

  @include breakpoint(phone-portrait) {
    padding-top: 42px;
    width: 100% !important;
    box-sizing: content-box;
  }
  &.menu--active {
    @include breakpoint(phone-portrait) {
      padding-left: 200px;
    }
  }
}

#root {
  background: $white;
  width: 100%;
}

.visuallyHidden {
  @include visuallyHidden;
}
