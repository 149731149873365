@import '../../mixins';

.offersAndVouchers {
  display: flex;

  @include breakpoint(phone-portrait) {
    flex-direction: column;
  }


  &__image {
    width: 100%;
  }

  &__col {
    width: 50%;
    box-sizing: border-box;
    padding: 0 20px;

    @include breakpoint(phone-portrait) {
      width: 100%;
    }
    
    h2.mainHeading, .mainHeading {
      width: 100%;
      position: relative;
      &__content {
        position: relative;
        z-index: 2;
      }

      &::before {
        content: '';
        background: $blue-lighter;
        width: 100vw;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
      }
      
      &::after {
        @include breakpoint(phone-portrait) {
          content: '';
          background: $blue-lighter;
          width: 100vw;
          height: 100%;
          position: absolute;
          z-index: 1;
          top: 0;
        }
      }
    }

    &:last-of-type {
      .mainHeading {
        &::before {
          left: 50%;
          @include breakpoint(phone-portrait) {
            left: -100%;
          }
        }
      }

      &::before {
        @include breakpoint(phone-portrait) {
          content: '';
          background: $blue-lighter;
          width: 100vw;
          position: absolute;
          z-index: 1;
          top: 0;
        }
      }

    }

    &:first-of-type {
      .mainHeading {
        &::before {
          left: -100%;
        }
      }
    }
  }

  &__form {
    font-family: 'Roboto', Roboto, Arial, sans-serif;
    
    &__fieldset {
      margin: 0;
      padding: 0;
      border: none;
    }

    &__item {
      display: flex;
      width: 100%;
      align-items: baseline;

      > input[type="radio"] {
        margin-right: 10px;
      }

      > select {
        font-family: 'Roboto', Roboto, Arial, sans-serif;
        width: 100%;
        display: block;
        font-size: 1em;
        line-height: 1.5em;
        margin: 0 0 10px;
        border: 1px solid #2b2e4a;
        background: #fff;
        border-radius: 0;
        -webkit-appearance: none;
        padding: 10px 25px 10px 10px;
        background: url('./dropdown-arrow.svg') no-repeat;
        background-position: right 10px center;
        background-size: 15px 10px;
      }

      > button {
        font-size: 1em;
        @include button();
        display: inline-block;
        margin: 1em 0 0 auto;
      }

      &__field {
        box-sizing: border-box;
        width: 100%;
        display: block;
        font-size: 1em;
        line-height: 1.5em;
        margin: 0 0 10px;
        border: 1px solid #2b2e4a;
        padding: 6px 10px;
        background: #fff;
        border-radius: 0;

        &__moneyHolder {
          display: flex;
          margin: 0 0 10px;
          flex-grow: 1;
          
          &::before {
            content: '£';
            border: 1px solid #2b2e4a;
            border-right: none;
            font-size: 1em;
            line-height: 1.5em;
            display: inline-block;
            padding-top: 6px;
            padding-left: 10px;
          }
        }
        
        &--money {
          margin: 0;
          display: inline-block;
          border-left: none;
        }
      }
    }
  }

  &__inputHolder {
    width: 100%;
    border: 1px solid $grey-darker;
    box-sizing: border-box;
    position: relative;
    padding: 1.2em 0.6em 0.3em;
    margin-bottom: 1em;

    &--textarea {
      padding-top: 1.4em;
    }

    &__label {
      position: absolute;
      top: 50%;
      left: 0;
      padding-left: 0.6em;
      transform: translateY(-50%);
      z-index: 1;
      width: 100%;
      color: $grey-darker;
      transition: all ease-in-out 0.1s;
      
      &--textarea {
        top: 9%;
        transform: none;
      }
    }
    &__input {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border: 0;
      padding: 4px;
      font-size: 1em;
      box-sizing: border-box;
      &:focus + label {
        font-size: 0.8em;
        top: 4%;
        transform: translateY(0%);
        color: $grey-darkest;
      }
      &:not(:placeholder-shown) + label {
        font-size: 0.8em;
        top: 0.2em;
        transform: translateY(0%);
        color: $grey-darkest;
      }

      &--textarea {
        resize: none;;
      }
    }
  }

}
