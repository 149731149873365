.hero {
  width: 100%;
  overflow: hidden;

  img {
    width: 100%;
  }

  @media (min-width: 1280px) {
    height: 700px;
  }
  @media (max-width: 1280px) {
    height: 600px;
  }
  @media (max-width: 1000px) {
    height: 500px;
  }
  @media (max-width: 850px) {
    height: 400px;
  }
  @media (max-width: 750px) {
    height: 400px;
  }
  @media (max-width: 675px) {
    height: 320px;
  }
  @media (max-width: 480px) {
    height: 250px;
  }
  @media (max-width: 420px) {
    height: 200px;
  }
  @media (max-width: 320px) {
    height: 180px;
  }
}
