@import "~video-react/styles/scss/video-react";
@import '../../mixins';

.onlinePT {
  &__pullout {
    text-align: center;
    font-size: 1em;
    &--first {
      font-size: 1.5em;
      font-weight: 100;
    }
    small {
      color: $grey;
      a {
        color: $grey;
        text-decoration: none;
      }
    }
    &--small {
      padding: 1.5em 0 0.5em;
    }
  }

  &__text {
    width: 48%;

    &__cta {
      text-align: center;
      &__button {
        font-size: 1.1em;
        @include button();
        display: inline-block;
        margin: 0 auto;
      }
    }
  }

  &__star {
    $bvSize: 50px;
    font-size: 0.9em;;
    background: $red;
    width: $bvSize;
    height: $bvSize;
    position: relative;
    text-align: center;
    color: $white;
    display: inline-block;
    transform: translateY(35%);
    z-index: 1;
    margin: 0 0.5em;
    font-weight: 500;

    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: $bvSize;
      width: $bvSize;
      background: $red;
      z-index: 2;
    }
    &::before {
      transform: rotate(30deg);
    }
    &::after {
      transform: rotate(60deg);
    }
    &--inner {
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      text-transform: uppercase;
    }
  }

  .testimonials__list {
    min-height: 240px;
  }

  &__form {
    &__fieldset {
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      border-top: 1px solid $blue;
      margin: 1.5em 0;
      
      &__legend {
        padding-inline-start: 0.4em;
        padding-inline-end: 0.4em;
        text-align: center;
        margin-bottom: 0.5em;
      }
    }

    &__inputHolder {
      width: 100%;
      border: 1px solid $grey-darker;
      box-sizing: border-box;
      position: relative;
      padding: 1.2em 0.6em 0.3em;
      margin-bottom: 1em;

      &--mobile, &--injuries, &--achieve, &--equipment {
        @include breakpoint(phone-portrait) {
          padding-top: 3em !important;
        }
      }

      &--textarea {
        padding-top: 1.4em;
      }
  
      &__label {
        position: absolute;
        top: 50%;
        left: 0;
        padding-left: 0.6em;
        padding-right: 0.6em;
        transform: translateY(-50%);
        z-index: 1;
        width: 100%;
        color: $grey-darkest;
        transition: all ease-in-out 0.1s;
        box-sizing: border-box;
        
        &--textarea {
          top: 0.6em;
          transform: none;
        }
      }
      &__input {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border: 0;
        padding: 4px;
        font-size: 1em;
        box-sizing: border-box;
        &:focus + label {
          font-size: 0.8em;
          top: 4%;
          transform: translateY(0%);
          color: $grey-darkest;
        }
        &:not(:placeholder-shown) + label {
          font-size: 0.8em;
          top: 0.2em;
          transform: translateY(0%);
          color: $grey-darkest;
        }
  
        &--textarea {
          resize: none;
        }
      }
      
      &__select {
        width: 100%;
        border: 0;
        box-sizing: border-box;
        position: relative;
        padding: 4px;
        appearance: none;
        border-radius: 0;
        background: transparent;
        font-size: 1em;
      }
    }

    &__selectHolder {
      position: relative;
      &::after {
        content: '';
        width: 0; 
        height: 0; 
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid $grey-darkest;
        position: absolute;
        right: 0.8em;
        top: 50%;
        transform: translateY(-50%);
      }
      .onlinePT__form__inputHolder__label {
        font-size: 0.8em;
        top: 0.2em;
        transform: translateY(0%);
        color: $grey-darkest;
      }
    }

    
    &__terms {
      width: 95%;
      margin: 0 auto;
      border: 1px solid $grey-lighter;
      background: $off-white;
      padding: 0 1em;
      font-size: 0.85em;
      text-align: justify;

      &Agreement {
        text-align: center;
        > input {
          margin-right: 0.8em;
        }
      }
    }
  }
}
  
.video-react-control-bar {
  font-family: 'Roboto', Roboto, Arial, sans-serif;
}
.video-react .video-react-big-play-button {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
