@import '../../mixins';

.testimonials {
  width: 100%;
  position: relative;

  &__pullout {
    @include contentWidth;
    margin: 1.7em auto;
    font-size: 1.5em;
    line-height: 1.2em;
    text-align: center;
  }

  &__list__container {
    background: $blue-lighter;
    overflow: hidden;
  }
  
  &__list {
    @include contentWidth;
    margin: 0 auto;
    background: $blue;
    color: #fff;
    position: relative;
    min-height: 180px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0;
    
    &__page {
      display: flex;
      justify-content: space-around;
      width: 100%;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      transition: opacity, left 0.5s, 0.5s ease-in-out;
      position: absolute;
      margin-left: 20px;
      
      @include breakpoint(phone-portrait) {
        flex-direction: column;
        width: 100%;
        top: 100%;
        transform: none;
      }
      
      &--current {
        opacity: 1;
        left: 0%;
        @include breakpoint(phone-portrait) {
          position: relative;
        }
      }

      &--previous {
        left: -100%;
        opacity: 0;
      }

      &--next {
        left: 100%;
        opacity: 0;
      }
    
      &__quote {
        width: 40%;
        padding: 20px 0;

        @include breakpoint(phone-portrait) {
          width: 90%;
        }
  
        &__text {
          width: calc(100% - 2em);
          margin: 0 auto;
          position: relative;
          padding: 0 10px;
          text-align: center;
          font-family: Open Sans, 'Open Sans', OpenSans, sans-serif;
          font-size: 1.5em;
          font-style: italic;
          font-weight: 300;
          line-height: 1.4em;

          &::before {
            content: '“';
            font-size: 3em;
            line-height: 1em;
            font-weight: 400;
            font-style: italic;
            font-family: ABeeZee, 'ABeeZee', sans-serif;
            position: absolute;
            top: 0;
            right: 100%;
          }
          &::after {
            content: '”';
            font-size: 3em;
            line-height: 1em;
            font-weight: 400;
            font-style: italic;
            font-family: ABeeZee, 'ABeeZee', sans-serif;
            position: absolute;
            bottom: 0;
            left: 98%;
            transform: translateY(75%);
          }
        }

        &__author {
          text-align: center;
          display: block;
          color: $grey;
          font-size: 1em;
          margin: 1em 0 0;
        }
      }
    }
  }
}
