@import '../../mixins';

.contact {
  padding: 1em 0 2em;

  &__form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 0 2em;

    @include breakpoint(phone-portrait) {
      flex-direction: column;
    }
  
    &__cta {
      @include button();
      font-size: 1em;
      margin: 0.5em 0 0 auto;
      float: right;
    }

    &__inputs {
      width: 48%;
      box-sizing: border-box;
    
      @include breakpoint(phone-portrait) {
        width: 100%;
        margin-bottom: 1em;
      }

      &__select {
        width: 100%;
        border: 1px solid $grey-darker;
        box-sizing: border-box;
        position: relative;
        padding: 1em 0.6em;
        appearance: none;
        border-radius: 0;
        background: transparent;
        font-size: 1em;
      }

      &__selectHolder {
        position: relative;
        &::after {
          content: '';
          width: 0; 
          height: 0; 
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 8px solid $grey-darkest;
          position: absolute;
          right: 0.8em;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      &__inputHolder {
        width: 100%;
        border: 1px solid $grey-darker;
        box-sizing: border-box;
        position: relative;
        padding: 1.2em 0.6em 0.3em;
        margin-bottom: 1em;

        &--textarea {
          padding-top: 1.4em;
        }
  
        &__label {
          position: absolute;
          top: 50%;
          left: 0;
          padding-left: 0.6em;
          transform: translateY(-50%);
          z-index: 1;
          width: 100%;
          color: $grey-darker;
          transition: all ease-in-out 0.1s;
          
          &--textarea {
            top: 0.62em;
            transform: none;
          }
        }
        &__input {
          position: relative;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border: 0;
          padding: 4px;
          font-size: 1em;
          box-sizing: border-box;
          &:focus + label {
            font-size: 0.8em;
            top: 4%;
            transform: translateY(0%);
            color: $grey-darkest;
          }
          &:not(:placeholder-shown) + label {
            font-size: 0.8em;
            top: 0.2em;
            transform: translateY(0%);
            color: $grey-darkest;
          }

          &--textarea {
            resize: none;;
          }
        }
      }
    }
  }

  &__call {
    margin: 1em 0;
    font-size: 1.6em;
    text-align: center;
    font-weight: 300;

    > a {
      font-size: 1.3em;
      text-decoration: none;
    }
  }

  &__smallprint {
    text-align: center;
  }
}
