@import '../../mixins';

.nutrition {
  padding: 1em 0 2em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include breakpoint(phone-portrait) {
    flex-direction: column-reverse;
  }

  &__text {
    width: 48%;
    @include breakpoint(phone-portrait) {
      width: 100%;
    }

    &__user {
      text-align: center;
      font-size: 1.4em;
      margin: 0 0 1em;
    }

    &__cta {
      text-align: center;
      &__button {
        font-size: 1.1em;
        @include button();
        display: inline-block;
        margin: 0 auto;
      }
    }
  }

  &__image {
    width: 48%;
    height: auto;
    @include breakpoint(phone-portrait) {
      width: 85%;
      margin: 0 auto 2em;
    }
  }
}
