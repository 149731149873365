@import '../../mixins';

.content {
  @include contentWidth;
  margin: 0 auto;

  h2.mainHeading, h1.mainHeading {
    text-align: center;
    width: 100vw;
    margin-left: 50%;
    transform: translateX(-50%);
    font-weight: 300;
    color: #fff;
    background: $blue-lighter;
    padding: 25px 0;
    margin-top: 0;
    font-size: 1.7em;
  }

  h3 {
    text-align: center;
    font-weight: 300;
    font-size: 1.4em;
  }

  a {
    color: $red;
  }
}
