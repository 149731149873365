@import '../../mixins';
$text-color: rgba(255, 255, 255, 0.6);

.footer {
  width: 100%;
  background: $blue-lighter;

  &__socialCopy__holder {
    background: $blue;
  }

  &__socialCopy {
    @include contentWidth;
    margin: 0 auto;
    color: $text-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
      width: 90% !important;
    }
  }
  
  &__copyright {
    text-align: right;
    font-size: 0.9rem;
  }
  
  &__companyreg {
    font-size: 0.8rem;
    text-align: center;
    color: $text-color;
    padding: 15px 0;

    p { 
      margin: 0;
      padding: 0;
    }
  }

  &__social {
    &__list {
      list-style: none;
      padding: 1em 0;
      margin: 0;
      display: flex;

      &__item {
        width: 36px;
        height: 36px;
        margin: 0 20px 0 0;
        display: block;

        &__link {
          width: 100%;
          height: 100%;
          display: block;
            
          &--twitter {
            background: url('./twitter.svg') no-repeat center;
          }

          &--facebook {
            background: url('./facebook.svg') no-repeat center;
          }

          &__image {
            width: 100%;
            height: 100%;
            display: block;  
            @include visuallyHidden();
          }
        }
      }
    }
  }
}