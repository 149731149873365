@import '../../mixins';

.pricing {
  padding: 1em 0 2em;

  &__timetable {
    margin-top: -1.5em;
    width: 100%;
    font-size: 1.3em;
    table-layout: fixed;
    border-spacing: 0 1em;

    .timetable {
      &__caption {
        @include visuallyHidden();
      }
      &__head {
        text-align: left;
        .timeHolder {
          font-weight: 400;
          transform: translateX(-50%);
          display: inline-block;
        }
        .timeLast {
          border: none !important
        }
      }
    }
    .timeLast {
      width: 5% !important;
      border: 1px solid $blue;
    }
    .day {
      width: 100px;
      text-align: center;
      padding-right: 5%;
    }
    .peak {
      text-align: center;
      padding: 0.8em 0;
      background: $blue;
      color: $white;
      &--offPeak {
        background: $blue-lighter;
      }
      &--morning {
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 20%;
          height: 100%;
          background: $white;
          position: absolute;
          right: 100%;
          top: 0;
          border: 1px solid $blue;
          box-sizing: border-box;
        }
      }
      &--sat {
        position: relative;
        &::before {
          content: '';
          display: block;
          width: 6.6%;
          height: 100%;
          background: $white;
          position: absolute;
          right: 100%;
          top: 0;
          border: 1px solid $blue;
          box-sizing: border-box;
        }
      }
    }
  }

  &__sessions {
    width: 100%;
    font-size: 1.3em;
    table-layout: fixed;
    border-spacing: 0 0.5em;
    padding: 2em 0;

    .sessionPrice {
      position: relative;
      text-align: center;
    }
    .cells30Min td {
      padding-bottom: 2em;
    }

    .peak {
      background: $blue;
      color: $white;
      display: block;
      padding: 0.6em 0;
      &--offPeak {
        background: $blue-lighter;
      }
    }
    $bvSize: 60px;
    .bestValue {
      font-size: 0.9em;;
      background: $red;
      width: $bvSize;
      height: $bvSize;
      position: absolute;
      text-align: center;
      color: $white;
      display: block;
      left: 100%;
      top: 100%;
      transform: translateX(-50%) rotate(-15deg) translateY(-30%);
      z-index: 1;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: $bvSize;
        width: $bvSize;
        background: $red;
        z-index: 2;
      }
      &::before {
        transform: rotate(30deg);
      }
      &::after {
        transform: rotate(60deg);
      }
      &--inner {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        text-transform: uppercase;
      }
    }
  }

  .circle {
    position: relative;
    padding-left: calc(40px + 1em);
    padding-top: 0.5em;
    display: inline-block;

    &::before {
      width: 40px;
      height: 40px;
      background: $white;
      border: 2px solid $blue-lighter;
      border-radius: 40px;
      content: '';
      display: block;
      top: 0;
      left: 0;
      position: absolute;
    }

    &--60Min {
      &::before {
        background: $blue-lighter;
      }
    }
    &--30Min {
      &::after {
        position: absolute;
        background: $blue-lighter;
        width: 20px;
        height: 40px;
        border: 2px solid $blue-lighter;
        border-radius: 0 40px 40px 0;
        content: '';
        display: block;
        top: 0;
        left: 20px;
      }
    }
  }

  &__text {
    width: 48%;

    &__cta {
      text-align: center;
      &__button {
        font-size: 1.1em;
        @include button();
        display: inline-block;
        margin: 0 auto;
      }
    }

    &__footnote {
      text-align: center;
      color: $grey-darker;
    }
  }

  &__hr {
    margin: 30px 0;
  }

  &__join {
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;

    @include breakpoint(phone-portrait) {
      flex-direction: column;
    }
  
  
    &__description, &__map {
      width: 48%;

      @include breakpoint(phone-portrait) {
        width: 100%;
      }
    
    }
    .chesham-moor-location {
      width: 100%;
    }

    &__description {
      &__title {
        font-size: 2em;
        font-weight: 100;
      }
      &__meta {
        font-size: 1.2em;
      }
      &__warning {
        font-size: 1.5em;
      }
    }
  }

}
